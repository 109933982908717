<template>
  <CModal
    :show.sync="boo_modal"
    :no-close-on-backdrop="true"
    :centered="false"
    title="Modal title 2"
    color="info"
  >
    <div class="row" v-if="item != undefined">
      <CCol lg="12" md="12" sm="12" xs="12">
        <CInput
          label="Total (S/)"
          placeholder="Total (S/)"
          v-model="item.total_tc"
          horizontal
          disabled
        />
      </CCol>
      <CCol lg="12" md="12" sm="12" xs="12">
        <CInput
          label="Porcentaje (%)"
          placeholder="Porcentaje (%)"
          v-model="item.detraccion_percentage"
          horizontal
          disabled
        />
      </CCol>
      <CCol lg="12" md="12" sm="12" xs="12">
        <CInput
          label="Deposito (S/)"
          placeholder="Deposito (S/)"
          v-model="item.detraccion_deposito"
          horizontal
          disabled
        />
      </CCol>
      <CCol lg="12" md="12" sm="12" xs="12">
        <CInput
          label="Fecha"
          placeholder="Fecha"
          v-model="item.detraccion_date"
          horizontal
          disabled
        />
      </CCol>
      <CCol lg="12" md="12" sm="12" xs="12">
        <CInput
          label="Tipo"
          placeholder="Tipo"
          v-model="item.detraccion_type"
          horizontal
          disabled
        />
      </CCol>
      <CCol lg="12" md="12" sm="12" xs="12">
        <CInput
          label="Serie"
          placeholder="Serie"
          v-model="item.detraccion_serie"
          horizontal
          disabled
        />
      </CCol>
      <CCol lg="12" md="12" sm="12" xs="12">
        <CInput
          label="Número"
          placeholder="Número"
          v-model="item.detraccion_number"
          horizontal
          disabled
        />
      </CCol>
    </div>
    <template #header>
      <h6 class="modal-title">{{ title }}</h6>
      <CButtonClose @click="mtd_close" class="text-white" />
    </template>
    <template #footer>
      <CButton @click="mtd_close" color="danger">Cerrar</CButton>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "c-modal-view",
  data() {
    return {};
  },
  props: {
    title: String,
    boo_modal: Boolean,
    item: {},
    size: String,
  },
  methods: {
    mtd_close() {
      this.$emit("close_view");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>