<template>
  <div>
    <CRow v-if="view == 'table'">
      <CCol sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de compras emitidos"
          icon="fas fa-cash-register"
          btn_name="compra"
          :size="'md'"
          :button_new="true"
          :actions="true"
          :withActions="'6%'"
          :buttonEdit="false"
          :buttonDelete="true"
          :myButtons="myButtons"
          @mtd_view_detraccion="mtd_view_detraccion"
          @show_modal="mtd_set_document"
          @action_delete="mtd_delete"
        />
      </CCol>
    </CRow>
    <CRow v-else>
      <CCol lg="6" sm="6" md="12" xs="12" class="text-left">
        <CButton size="sm" color="warning" @click="mtd_return_table">
          <i class="fas fa-angle-left"></i> Regresar
        </CButton>
      </CCol>
      <CCol lg="6" sm="6" md="12" xs="12" class="text-right">
        <CButton
          size="sm"
          color="info"
          v-c-tooltip.hover="{
            content: 'Registrar documento',
          }"
          @click="mtd_save"
          :disabled="cp_disabled_save"
        >
          <i class="fas fa-save"></i> Registrar
        </CButton>
      </CCol>
      <CCol class="mt-2" lg="12" sm="12" md="12" xs="12">
        <CCard>
          <CCardHeader>
            <strong> DOCUMENTO</strong>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol lg="2" md="2" sm="6" xs="12">
                <label for="">Tipo documento </label>
                <v-select
                  :options="data_type_expense"
                  v-model="document.type_expense"
                  placeholder="Seleccione tipo"
                />
              </CCol>
              <CCol lg="10" md="10" sm="6" xs="12">
                <label for="">Elegir proveedor</label>
                <v-select
                  :options="data_supplier"
                  v-model="document.supplier_expense"
                  placeholder="Seleccione proveedor"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12" class="mt-2">
                <CInput
                  label="Fecha"
                  placeholder="Fecha"
                  v-model="document.date"
                  type="date"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12" class="mt-2">
                <CInput
                  label="Fecha Vto"
                  placeholder="Fecha Vto"
                  v-model="document.date_end"
                  :min="document.date"
                  type="date"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12" class="mt-2">
                <label for="">Tipo documento</label>
                <v-select
                  :options="data_typedocument"
                  v-model="document.type_document"
                  placeholder="Tipo doc."
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12" class="mt-2">
                <CInput
                  label="Serie"
                  placeholder="Serie"
                  v-model="document.serie"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12" class="mt-2">
                <CInput
                  label="Número doc."
                  placeholder="Número doc."
                  v-model="document.number"
                  @blur="mtd_verif_document"
                />
              </CCol>
              <CCol lg="2" md="2" sm="12" xs="12" class="mt-2">
                <label for="">Moneda</label>
                <v-select
                  :options="data_current"
                  v-model="document.current"
                  placeholder="Seleccione moneda"
                  @input="mtd_select_current"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Adq. no gravadas"
                  placeholder="Adq. no gravadas"
                  v-model="document.adquisiciones_nogravadas"
                  v-on:keypress="isNumber($event)"
                  @blur="mtd_out_focus"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Percepción"
                  placeholder="Percepción"
                  v-model="document.percepcion"
                  v-on:keypress="isNumber($event)"
                  @blur="mtd_out_focus"
                />
              </CCol>
              <CCol lg="2" md="2" sm="12" xs="12">
                <label for="">Igv</label>
                <v-select
                  :options="data_igv"
                  v-model="document.type_igv"
                  placeholder="Seleccione igv"
                  :disabled="document.adquisiciones_nogravadas > 0 || document.percepcion > 0 ? true:false"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Base imponible"
                  placeholder="Base imponible"
                  v-model="document.base"
                  v-on:keypress="isNumber($event)"
                  @blur="mtd_out_focus"
                  :disabled="document.adquisiciones_nogravadas > 0 || document.percepcion > 0 ? true:false"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Factor igv"
                  placeholder="Factor igv"
                  v-model="document.igv_factor"
                  v-on:keypress="isNumber($event)"
                  @blur="mtd_out_focus"
                  :disabled="document.adquisiciones_nogravadas > 0 || document.percepcion > 0 ? true:false"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Igv"
                  placeholder="Igv"
                  v-model="cp_igv"
                  v-on:keypress="isNumber($event)"
                  :disabled="true"
                />
              </CCol>
              
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Igv ret"
                  placeholder="Igv ret"
                  v-model="document.igv_ret"
                  v-on:keypress="isNumber($event)"
                  @blur="mtd_out_focus"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Total"
                  placeholder="Total"
                  v-model="cp_total_sintc"
                  @blur="mtd_out_focus"
                  v-on:keypress="isNumber($event)"
                  :disabled="true"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput label="TC" placeholder="TC" v-model="document.tc" />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Total soles"
                  placeholder="Total soles"
                  v-model="cp_total_contc"
                  :disabled="true"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  type="checkbox"
                  label="Detracción"
                  @change="mtd_select_detraccion()"
                  size="sm"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12"> </CCol>
              <CCol lg="2" md="2" sm="6" xs="12" v-if="detraccion === true">
                <CInput
                  label="Detraccion %"
                  placeholder="Detraccion %"
                  v-model="document.detraccion_percentage"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12" v-if="detraccion === true">
                <CInput
                  label="Deposito"
                  placeholder="Deposito"
                  v-model="cp_detraccion_deposito"
                  :disabled="true"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12" v-if="detraccion === true">
                <CInput
                  type="date"
                  label="Fecha"
                  placeholder="Fecha"
                  v-model="document.detraccion_date"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12" v-if="detraccion === true">
                <CInput
                  label="Tipo"
                  placeholder="Tipo"
                  v-model="document.detraccion_type"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12" v-if="detraccion === true">
                <CInput
                  label="Serie"
                  placeholder="Serie"
                  v-model="document.detraccion_serie"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12" v-if="detraccion === true">
                <CInput
                  label="Número"
                  placeholder="Número"
                  v-model="document.detraccion_number"
                />
              </CCol>
              <CCol lg="12" md="12" sm="6" xs="12">
                <CInput
                  label="Descripción para el documento"
                  placeholder="Descripción para el documento"
                  v-model="document.description"
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- modal delete -->
    <cModalDelete
      title="Borrar Compra"
      :boo_modal="modal_delete.boo"
      :item="modal_delete.item"
      @close_delete="close_delete"
      @mtd_commit="mtd_commit"
    ></cModalDelete>

    <!-- modal view detraccion -->
    <cModalViewDetraccion
      title="Ver detracción"
      :boo_modal="modal_view.boo"
      :item="modal_view.item"
      @close_view="close_view"
    ></cModalViewDetraccion>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { bus } from "../../main";

import cModalDelete from "../../components/shared/modals/cModalDelete.vue";
import CTableWrapper from "../../components/shared/datatable/Table.vue";
import cModalViewDetraccion from '../../components/shared/purchase/cModalViewDetraccion.vue';

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

const fields = [
  { key: "Id", _style: "width:3%" },
  { key: "type_expense", label: "Tipo doc. prov.", _style: "width:20%;" },
  { key: "supplier", label: "Proveedor", _style: "width:20%;" },
  { key: "date", label: "Fecha", _style: "width:20%;" },
  { key: "date_end", label: "Fecha Vto", _style: "width:20%;" },
  { key: "type_document", label: "Tipo doc.", _style: "width:20%;" },
  { key: "num_doc", label: "Serie", _style: "width:20%;" },
  { key: "type_igv", label: "Tipo igv", _style: "width:20%;" },
  { key: "base", label: "Base", _style: "width:20%;" },
  { key: "igv_factor", label: "Fac. igv", _style: "width:20%;" },
  { key: "igv", label: "Igv", _style: "width:20%;" },
  { key: "adquisiciones_nogravadas", label: "Adquisiones no grav.", _style: "width:20%;" },
  { key: "percepcion", label: "Percepcón", _style: "width:20%;" },
  { key: "igv_ret", label: "Igv ret", _style: "width:20%;" },
  { key: "total", label: "Total", _style: "width:20%;" },
  { key: "tc", label: "Tipo cambio", _style: "width:20%;" },
  { key: "total_tc", label: "Total Soles", _style: "width:20%;" },
  { key: "description", label: "Descripción", _style: "width:20%;" },
];

export default {
  name: "v-sale-document",
  components: { CTableWrapper, vSelect, cModalDelete,cModalViewDetraccion },
  data() {
    return {
      prefix: "purchase/document",
      view: "table",
      fields,
      hoy: null,
      data: [],
      myButtons: [
        {
          class: "btn-sm",
          color: "info",
          tooltip: "Ver Detracción",
          action: "mtd_view_detraccion",
          icon: "fas fa-eye",
          show: {
            0: false,
            1: true,
          },
        },
      ],
      data_type_expense: [
        { value: "6", label: "RUC" },
        { value: "1", label: "DNI" },
      ],
      data_typedocument: [
        { value: 3, label: "BOLETA" },
        { value: 1, label: "FACTURA" },
      ],
      data_current: [
        { value: "SOLES", label: "SOL" },
        { value: "DOLARES", label: "DOLAR" },
      ],
      data_igv: [
        { value: 1, label: "TIPO 1" },
        { value: 2, label: "GASTOS X SERVICIO" },
        { value: 3, label: "TIPO 3" }
      ],
      data_supplier: [],
      detraccion: false,
      document: {
        type_expense: [],
        supplier_expense: null,
        date: null,
        date_end: null,
        type_document: null,
        serie: "",
        number: "",
        current: [],
        type_igv: [],
        base: "",
        igv_factor: "",
        igv: "",
        adquisiciones_nogravadas: "",
        percepcion: "",
        igv_ret: "",
        total: "",
        tc: "",
        total_tc: "",
        detraccion_percentage: "",
        detraccion_deposito: "",
        detraccion_date: "",
        detraccion_type: "",
        detraccion_serie: "",
        detraccion_number: "",
        description: "",
      },
      /** modal delete */
      modal_delete: {
        boo: false,
        item: [],
      },
      /** modal detración */
      det_table_view: [],
      modal_view: {
        boo: false,
        item: [],
      },
    };
  },
  computed: {
    cp_igv: function () {
      let igv = 0;
      if (this.document.base > 0 && this.document.igv_factor > 0) {
        igv = parseFloat((this.document.base * this.document.igv_factor) / 100);
      }
      this.document.igv = parseFloat(igv).toFixed(2);
      return this.document.igv;
    },
    cp_total_sintc: function () {
      let res = 0;
      // if (
      //   //  this.document.base !="" &&
      //   //  this.document.igv != "" &&
      //   //  this.document.adquisiciones_nogravadas != "" &&
      //   //  this.document.percepcion != "" &&
      //    this.document.igv_ret != ""
      // ) {
        res =
          parseFloat(this.document.base) +
          parseFloat(this.document.igv) +
          parseFloat(this.document.adquisiciones_nogravadas) +
          parseFloat(this.document.percepcion) +
          parseFloat(this.document.igv_ret);
      // }
      this.document.total = Number.isNaN(res) == false? parseFloat(res).toFixed(2):0;
      return this.document.total;
    },
    cp_total_contc: function () {
      let res = 0;
      if (this.document.total > 0 && this.document.tc > 0) {
        res = parseFloat(this.document.total) * parseFloat(this.document.tc);
      }
      this.document.total_tc = parseFloat(res);
      return this.document.total_tc;
    },
    cp_detraccion_deposito: function(){
      let tot = 0;
      if(this.document.detraccion_percentage > 0 && this.document.total_tc > 0){
        tot = parseFloat(this.document.detraccion_percentage) * parseFloat(this.document.total_tc);
      }
      this.document.detraccion_deposito = parseFloat(tot).toFixed(2);
      return this.document.detraccion_deposito;
    },
    cp_disabled_save: function () {
      if (this.document.description.length > 0 && this.document.total_tc > 0)
        return false;
      return true;
    },
  },
  created() {
    let actual = new Date();
    let fecha;
    fecha =
      actual.getFullYear() +
      "-" +
      ("0" + (actual.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + actual.getDate()).slice(-2);
    this.hoy = fecha;
    this.mtd_getData();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getData: function () {
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data;
          this.data_supplier = response.data_supplier;
        })
        .catch((errors) => {});
    },
    mtd_set_document: function (item) {
      this.view = "document";
      this.document = {
        type_expense: [],
        supplier_expense: [],
        date: this.hoy,
        date_end: this.hoy,
        type_document: [],
        serie: "",
        number: "",
        current: [],
        type_igv: [],
        base: "",
        igv_factor: "",
        igv: "",
        adquisiciones_nogravadas: "",
        percepcion: "",
        igv_ret: "",
        total: "",
        tc: "",
        total_tc: "",
        detraccion_percentage: "",
        detraccion_deposito: "",
        detraccion_date: this.hoy,
        detraccion_type: "",
        detraccion_serie: "",
        detraccion_number: "",
        description: "",
      };
    },
    mtd_select_detraccion: function () {
      this.detraccion = !this.detraccion;
      if (this.detraccion == false) {
        this.document.detraccion_percentage= "";
        this.document.detraccion_deposito= "";
        this.document.detraccion_date= "";
        this.document.detraccion_type= "";
        this.document.detraccion_serie= "";
        this.document.detraccion_number= "";
      }
    },
    mtd_return_table: function () {
      this.view = "table";
    },
    mtd_out_focus: function () {
      this.document.base =
        this.document.base > 0
          ? parseFloat(this.document.base).toFixed(2)
          : 0.0;
      this.document.igv_factor =
        this.document.igv_factor > 0
          ? parseFloat(this.document.igv_factor).toFixed(2)
          : 0.0;
      this.document.adquisiciones_nogravadas =
        this.document.adquisiciones_nogravadas > 0
          ? parseFloat(this.document.adquisiciones_nogravadas).toFixed(2)
          : 0.0;
      this.document.percepcion =
        this.document.percepcion > 0
          ? parseFloat(this.document.percepcion).toFixed(2)
          : 0.0;
      this.document.igv_ret =
        this.document.igv_ret > 0
          ? parseFloat(this.document.igv_ret).toFixed(2)
          : 0.0;
    },
    mtd_save: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/store",
        token: this.$store.getters.get__token,
        params: this.document,
      })
        .then((response) => {
          if (response.state == 0) {
            this.mtd_return_table();
            this.mtd_getData();
            bus.$emit("alert", {
              color: "success",
              message: "DOCUMENTO EMITIDO CORRECTAMENTE!!",
            });
          } else {
            bus.$emit("alert", {
              color: "warning",
              message: response.message,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    /** delete */
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.Id,
        },
      })
        .then((response) => {
          if (response.state == 0) {
            bus.$emit("alert", {
              color: "success",
              message: "ELIMINADO CORRECTAMENTE!!",
            });  
            this.mtd_getData();
          }else if(response.state == 2){
            bus.$emit("alert", {
              color: "warning",
              message: response.message,
            });  
          }else{
            bus.$emit("alert", {
              color: "danger",
              message: response.message,
            });  
          }
          this.close_delete();
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_select_current: function(){
      if (this.document.current.value == 'SOLES') {
        this.document.tc = 1;
        this.document.total_tc=this.document.total;
      } else {
        this.document.total_tc=parseFloat(this.document.total * this.document.tc).toFixed(2);
      }
    },
    mtd_verif_document: function(){
      console.log('verif');
      if (this.document.supplier_expense != null && this.document.type_document != null && this.document.serie.length > 0 && this.document.number.length > 0) {
        this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/verif",
        token: this.$store.getters.get__token,
        params: this.document
      })
        .then((response) => {
          if (response.state == 0) {
            bus.$emit("alert", {
              color: "success",
              message: "DOCUMENTO CORRECTO",
            });  
          }else{
            bus.$emit("alert", {
              color: "warning",
              message: "DOCUMENTO DUPLICADO",
            });  
          }
          this.close_delete();
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
      }else{
        bus.$emit("alert", {
          color: "warning",
          message: "DIGITE TODOS LOS CAMPOS PARA VALIDAR DOCUMENTO!!",
        });  
      }
    },
    //** aun */
    mtd_view_detraccion: function (item) {
      this.modal_view.boo = true;
      this.modal_view.item = item.detraccion;
    },
    close_view: function () {
      this.modal_view = {
        boo: false,
        item: [],
      };
    },

    /** helpers */
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
